import React from "react"
import { Container } from 'react-bootstrap'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

//components
import Layout from "../components/layout"
import CollectionCards from '../components/collection-cards'
import RenderMultiLine from '../components/RenderMultiline'
import SEO from "../components/seo"

const IndexPage = ({data}) => {
    const pageData = data.contentfulPage;
    return(
        <Layout>
            <SEO />
            <section className="section">
                <Container>
                    <div className="has-padding-large has-bg-white">
                        <h1 className="heading">De Bibliotheek Verrijker</h1>
                        <section className="section-intro wysiwyg">
                            <RenderMultiLine string={pageData.introduction.introduction}/>
                        </section>
                        <section className="section-body wysiwyg">
                            {documentToReactComponents(data.contentfulPage.content.json)}
                        </section>
                    </div>
                </Container>
            </section>
            
            <section className="section">
                <Container fluid>
                    <div className="section-intro">
                        <h2 className="heading section-heading">Alle collecties</h2>
                    </div>
                    <CollectionCards collectionItems={data.allContentfulCollection.edges}/>
                </Container>
            </section>
            
        </Layout>
    )
}

export const query = graphql`
    query {
        allContentfulCollection{
            edges {
                node {
                    slug
                    title
                    image {
                        fluid(maxWidth:400, maxHeight:400){
                            ...GatsbyContentfulFluid
                        }
                    }
                    tags {
                        tag
                        slug
                    }
                }
            }
        }
        contentfulPage(slug: {eq: "home"}) {
            introduction {
                introduction
            }
            content {
                json
            }
        }
    }
`

export default IndexPage

